<template>
  <div class="discover">
    <div class="banner">
      <div class="container">
        <strong>{{ $t(`Layout['优付商户']`) }}</strong>
        <div class="search-box">
          <input type="text" />
        </div>
        <div class="select-box">
          <el-select v-model="areaValue" placeholder="区域">
            <el-option
              v-for="item in areaOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="categoryValue" placeholder="类别">
            <el-option
              v-for="item in categoryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="typeValue" placeholder="产业类型">
            <el-option
              v-for="item in categoryValue
                ? categoryOptions[categoryValue].types
                : []"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
            <template v-slot:empty>
              <p class="el-select-dropdown__item" style="color: #aaa">
                请先选择类别
              </p></template
            >
          </el-select>
          <el-select v-model="areaValue" placeholder="合作方案标签">
            <el-option
              v-for="item in areaOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="content container">
      <!-- <ul class="category">
        <li v-for="(item, i) in categoryList" :key="i">
          <img :src="item.img" alt="" />
          <p class="img-top-text">{{ item.name }}</p>
        </li>
      </ul> -->
      <div class="search-list">
        <p class="result">搜索到90个关于"披萨"</p>
        <ul>
          <li class="item" v-for="item in 6" :key="item">
            <img class="shop-img" src="" alt="" />
            <div class="text">
              <span class="flag">最多高达3％ 现今返现</span>
              <p class="shop-name">
                <strong
                  >店铺名称店铺名称店铺名称店铺名称店铺名称店铺名称店铺名称店铺...</strong
                >
              </p>
              <p class="address">
                这里是店铺地址这里是店铺地址这里是店铺地址这
                里是店铺地址这里是店铺地址这里是店铺地址这里是店铺地址这里是店铺地址这里是店铺地址这里是店铺
              </p>
              <div class="box-footer">
                <img
                  src="../../assets/image/ewallet/优付商户/营业时间icon.png"
                  alt=""
                />10:00am - 10:00pm
              </div>
              <div class="box-footer">
                <img
                  src="../../assets/image/ewallet/优付商户/联系方式icon.png"
                  alt=""
                />15006099788；15006099789
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Pagenation :pages="6"></Pagenation>
    </div>
  </div>
</template>

<script>
import Pagenation from '../../components/Pagenation'
export default {
  name: 'EWallet',
  components: {
    Pagenation
  },
  data() {
    return {
      areaOptions: [
        {
          value: '桑园区',
          label: '桑园区'
        },
        {
          value: '隆边区',
          label: '隆边区'
        },
        {
          value: '马卡拉区',
          label: '马卡拉区'
        },
        {
          value: '堆谷区',
          label: '堆谷区'
        },
        {
          value: '索森区',
          label: '索森区'
        },
        {
          value: '波森芷区',
          label: '波森芷区'
        },
        {
          value: '棉芷区',
          label: '棉芷区'
        },
        {
          value: '东阁区',
          label: '东阁区'
        },
        {
          value: '铁桥头区',
          label: '铁桥头区'
        },
        {
          value: '水浄华区',
          label: '水浄华区'
        },
        {
          value: '亚西阁区',
          label: '亚西阁区'
        },
        {
          value: '普诺夫溪区',
          label: '普诺夫溪区'
        }
      ],
      areaValue: '',
      categoryOptions: [
        {
          value: 1,
          label: '美食',
          types: [
            { value: '中餐', label: '中餐' },
            { value: '西餐', label: '西餐' },
            { value: '日料', label: '日料' },
            { value: '咖啡厅', label: '咖啡厅' },
            { value: '速食', label: '速食' },
            { value: '南洋餐', label: '南洋餐' },
            { value: '烘焙', label: '烘焙' },
            { value: '饮品', label: '饮品' },
            { value: '小吃', label: '小吃' }
          ]
        },
        {
          value: 2,
          label: '美容',
          types: [
            { value: '按摩', label: '按摩' },
            { value: '水疗舒压', label: '水疗舒压' },
            { value: '美发', label: '美发' },
            { value: '美甲', label: '美甲' }
          ]
        },
        {
          value: 3,
          label: '购物',
          types: [
            { value: '美妆', label: '美妆' },
            { value: '流行、服饰', label: '流行、服饰' },
            { value: '生活用品', label: '生活用品' },
            { value: '数码家电', label: '数码家电' },
            { value: '母婴 玩具', label: '母婴 玩具' }
          ]
        },
        {
          value: 4,
          label: '宠物 ',
          types: [
            { value: '宠物医院', label: '宠物医院' },
            { value: '宠物医院', label: '宠物医院' }
          ]
        },
        {
          value: 5,
          label: '生活服务',
          types: [
            { value: '饭店、民宿', label: '饭店、民宿' },
            { value: '娱乐活动', label: '娱乐活动' },
            { value: '运动健身', label: '运动健身' },
            { value: '交通运输', label: '交通运输' },
            { value: '汽车美容', label: '汽车美容' },
            { value: '医院诊所', label: '医院诊所' },
            { value: '药局', label: '药局' }
          ]
        },
        {
          value: 6,
          label: '便利店、超市',
          types: [
            { value: '便利店', label: '便利店' },
            { value: '超市', label: '超市' }
          ]
        }
      ],
      categoryValue: '',
      typeValue: '',
      categoryList: [
        {
          name: '美食',
          img: require('../../assets/image/ewallet/优付商户/美食.jpg')
        },
        {
          name: '美容',
          img: require('../../assets/image/ewallet/优付商户/美容.jpg')
        },
        {
          name: '购物',
          img: require('../../assets/image/ewallet/优付商户/购物.jpg')
        },
        {
          name: '宠物',
          img: require('../../assets/image/ewallet/优付商户/宠物.jpg')
        },
        {
          name: '生活服务',
          img: require('../../assets/image/ewallet/优付商户/生活服务.jpg')
        },
        {
          name: '便利店、超市',
          img: require('../../assets/image/ewallet/优付商户/便利店、超市.jpg')
        }
      ]
    }
  },
  computed: {}
}
</script>

<style lang="less" scoped>
.discover {
  .banner {
    min-height: 550px;
    background: url("../../assets/image/ewallet/banner@2x.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    color: #ffffff;
    .container {
      padding-top: 170px;
      line-height: 1.3;
      text-align: center;
      strong {
        font-size: 40px;
        font-weight: 300;
      }
      .search-box input {
        margin-top: 59px;
        width: 100%;
        max-width: 900px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 6px;
        outline: none;
        text-align: center;
        font-size: 18px;
      }
      /deep/.select-box {
        margin-top: 50px;
        .el-select {
          width: 180px;
          margin: 0 30px 20px;
          .el-input__inner {
            background-color: unset;
            border: none;
            border-radius: unset;
            color: #fff;
            border-bottom: 1px solid #fff;
          }
          .el-icon-arrow-up:before {
            content: "\e78f";
          }
        }
      }
    }
    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      .container {
        padding: 80px 18px 0;
        text-align: center;
        strong {
          font-size: 32px;
        }
      }
      max-height: 400px;
    }
  }
  .content {
    padding: 60px 0 100px;
    .category {
      padding: 180px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        position: relative;
        margin: 0 12px 25px;
        border-radius: 6px;
        overflow: hidden;
        img {
          width: 350px;
          height: 250px;
        }
        .img-top-text {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          font-size: 36px;
          font-weight: 500;
          color: #ffffff;
          line-height: 250px;
          text-align: center;
          transition: opacity 0.3s;
          cursor: pointer;
        }
        &:hover {
          .img-top-text {
            opacity: 0;
          }
        }
      }
    }

    .search-list {
      color: #333333;
      padding-bottom: 30px;
      .result {
        margin-left: 50px;
        font-size: 18px;
        margin-bottom: 30px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .item {
          width: 350px;
          box-sizing: border-box;
          border: 1px solid #dddddd;
          border-radius: 6px;
          overflow: hidden;
          margin: 0 12.5px 25px;
          .shop-img {
            width: 350px;
            height: 200px;
          }
          .text {
            padding: 10px 20px 20px;
            .flag {
              font-size: 12px;
              color: #ffffff;
              padding: 3px 8px;
              background: #fec20f;
              border-radius: 4px;
            }
            .shop-name {
              width: 310px;
              margin: 16px 0;
              font-size: 18px;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .address,
            .box-footer {
              display: -webkit-box;
              max-height: 3em;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              font-size: 14px;
              color: #666666;
            }
            .box-footer {
              margin-top: 6px;
              img {
                height: 20px;
                width: 20px;
                vertical-align: top;
                margin-right: 3px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
